import { create } from 'zustand'

interface UserState {
  isIdle?: boolean
  setIsIdle: (isIdle: boolean) => void
}

const useUserStore = create<UserState>(set => ({
  isIdle: false,
  setIsIdle: (isIdle: boolean) => set({ isIdle }),
}))

export default useUserStore
