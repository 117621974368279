'use client'

import { AppSetup } from '@ethena/shared-ui/src/components/AppSetup'
import { TRANSPORTS } from '@ethena/shared-ui/src/constants/transports'
import { useIdle } from '@ethena/shared-ui/src/hooks/useIdle'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect } from 'react'
import { createConfig, WagmiProvider } from 'wagmi'
import { localhost, mainnet } from 'wagmi/chains'

import useUserStore from '@/store/user-store'

import { LandingWrapper } from './landing-wrapper'

interface GlobalContainerProps {
  children: React.ReactNode
}

export const GlobalContainer = ({ children }: GlobalContainerProps) => {
  const { setIsIdle } = useUserStore()
  const idle = useIdle(120_000)

  useEffect(() => {
    setIsIdle(idle)
  }, [idle, setIsIdle])

  const queryClient = new QueryClient()

  const wagmiConfig = createConfig({
    chains: [process.env.NEXT_PUBLIC_PLAYWRIGHT_ENABLED === 'true' ? localhost : mainnet],
    ssr: true,
    transports: TRANSPORTS,
  })

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <AppSetup>
          <LandingWrapper>{children}</LandingWrapper>
        </AppSetup>
      </QueryClientProvider>
    </WagmiProvider>
  )
}
