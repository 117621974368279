'use client'

import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { FaDiscord } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { GrPowerReset } from 'react-icons/gr'

import { DISCORD_LINK } from '../constants/socials'
import { useIsHydrated } from '../hooks/useIsHydrated'

import { FooterStat } from './FooterStat'
import { StyledALink } from './StyledALink'

interface SocialsProps {
  className?: string
  handleReset?: () => void
  tvl?: string
  susdeYield?: string
}

export const Socials = ({ className, handleReset, susdeYield, tvl }: SocialsProps) => {
  const isHydrated = useIsHydrated()
  const pathname = usePathname()
  const [showImprint, setShowImprint] = useState(false)

  useEffect(() => {
    setShowImprint(isHydrated && (pathname?.includes('/buy') ?? false))
  }, [isHydrated, pathname])

  return (
    <div
      className={clsx(
        'flex flex-wrap justify-center items-center',
        handleReset && 'animate-in slide-in-from-right duration-500',
        className,
      )}
    >
      {showImprint && (
        <StyledALink
          url="https://ethena.gmbh"
          classes="text-sm text-[#808080] hover:text-neutrals-400 transition-colors animate-in fade-in duration-500"
          text="Imprint"
          hideUnderline
        />
      )}
      <Link href={DISCORD_LINK} target="_blank">
        <FaDiscord className="text-[#808080] h-5 w-auto" width={0} height={0} />
      </Link>
      <Link href="https://twitter.com/ethena_labs" target="_blank">
        <FaXTwitter className="text-[#808080] h-5 w-auto" width={0} height={0} />
      </Link>
      <Link href="https://ethena-labs.gitbook.io/ethena-labs/" target="_blank">
        <Image src="/shared/gitbook.svg" width={0} height={0} alt="gitbook" className="h-5 w-auto" />
      </Link>
      <Link href="https://t.me/ethena_labs" target="_blank">
        <Image src="/shared/telegram.svg" width={0} height={0} alt="telegram" className="h-5 w-auto" />
      </Link>
      <Link href="https://github.com/ethena-labs" target="_blank">
        <Image src="/shared/github.svg" width={0} height={0} alt="github" className="h-5 w-auto" />
      </Link>
      <Link href="https://ethena.notion.site/Ethena-FAQs-3ccc1437e13343f8b74c0d005e4f5128" target="_blank">
        <Image src="/shared/notion.svg" width={0} height={0} alt="notion" className="h-5 w-auto" />
      </Link>
      <Link href="https://mirror.xyz/0xF99d0E4E3435cc9C9868D1C6274DfaB3e2721341" target="_blank">
        <Image src="/shared/mirror.svg" width={0} height={0} alt="mirror" className="h-3.5 w-auto" />
      </Link>

      {handleReset && (
        <>
          <button className="text-[#808080] h-5 w-auto" title="Reset Wallets" onClick={handleReset}>
            <GrPowerReset />
          </button>
          <FooterStat title="APY" stat={susdeYield} />
          <FooterStat title="TVL" stat={tvl} />
        </>
      )}
    </div>
  )
}
